.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: z("modal");
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  will-change: opacity;
  background: $main-bg;

  svg {
    color: $grey-200;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  &__icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: grid;
    place-items: center;
    position: absolute;
    border: $border-gray;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      border-color: rgba(38, 38, 35, 0.35);
    }
  }

  &__arrow {
    top: 50%;
    transform: translateY(-50%);
    background: $main-bg;

    &--left {
      left: 40px;

      @include media("<=tablet") {
        left: 8px;
      }
    }

    &--right {
      right: 40px;
      @include media("<=tablet") {
        right: 8px;
      }
    }
  }

  &__close {
    top: 40px;
    right: 40px;

    @include media("<=tablet") {
      right: 20px;
    }
  }

  &__owner {
    left: 40px;

    @include media("<=tablet") {
      left: 20px;
    }
  }

  &__title {
    right: 40px;
    color: $grey-100;

    @include media("<=tablet") {
      right: 20px;
    }
  }

  p {
    @extend %font-buttons;
    position: absolute;
    bottom: 40px;
  }

  &__video {
    width: 100%;
    height: 100%;
    padding-left: 15.8rem;
    padding-right: 15.8rem;
    padding-top: 5rem;
    max-width: 1300px;
    margin: 0 auto;

    @include media("<=tablet") {
      padding: 0;
    }

    .c-video {
      @include media("<=tablet") {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--yt {
    .c-modal {
    }
  }

  &--thumbnail {
    &__images {
      display: grid;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__image {
      grid-area: 1/1;
      opacity: 0;
      transition: opacity 0.7s ease-out;

      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .c-modal {
      &__video {
        padding-top: 0;
        padding-bottom: 0;

        @include media("<=tablet") {
          padding-left: 24px;
          padding-right: 24px;
        }

        .c-video {
          top: 50%;
          transform: translateY(-50%);

          @include media("<=tablet") {
            position: relative;
          }
        }
      }
    }
  }
}
