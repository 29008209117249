.home {
  @extend %cover;
  @extend %page;
  transition: opacity 0.4s ease, visibility 0.4s ease;

  &--active {
    @extend %page--active;
  }
}

.home__wrapper {
  @extend %cover;
  height: auto;
  will-change: transform;

  .c-video {
    &__play {
      z-index: 1;
    }
    &--home {
      z-index: -1;
      &::before {
        @include media(">tablet") {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 50%;
          width: 100%;
          background: linear-gradient(
            180deg,
            rgba(38, 38, 35, 0.75) 0%,
            rgba(38, 38, 35, 0) 100%
          );
          z-index: 1;
          pointer-events: none;
        }
      }
    }
  }
}

.home__nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  // z-index: 1;

  @include media("<=tablet") {
    flex-direction: column;
  }

  @include media(">tablet") {
    position: absolute;
    align-items: flex-start;
    color: $white;
  }

  &__buttons {
    display: flex;
    width: 100%;

    &.show-mobile {
      @extend %tablet;
    }
  }

  &__link {
    &__parent {
      width: 100%;
      max-width: 17.9rem;
      border-right: $border-gray;
      display: grid;
      place-items: center;

      &.show-desktop {
        @extend %desktop-tab;

        &.second {
          border-right: 0;
          border-left: $border-gray;
        }
      }

      @include media("<=tablet") {
        max-width: 50%;
        width: 100%;
        border-top: $border-gray;
      }

      button {
        @extend %font-buttons;
        position: relative;
        background: transparent;

        span {
          padding: 4.8rem;
          display: inline-block;
          position: relative;
          z-index: 3;
        }

        @include media("<=tablet") {
          padding: 24px 0;
        }

        @include media(">tablet") {
          color: $white;
        }
      }
    }
  }

  &__content {
    padding: 4.8rem 0;
    text-align: center;
  }

  &__title {
    @extend %title-h1;
    margin-bottom: 4px;

    @include media(">tablet") {
      color: $white;
    }
  }

  &__subtitle {
    @extend %subtitles;
  }
}

.home__bottom {
  background: #f6f2ea;
  position: relative;

  @include media("<=tablet") {
    background: none;

    &::before {
      background: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 90rem;
    width: 100%;
    top: -6rem;
    background: linear-gradient(
      180deg,
      #f2f1e9 0%,
      rgba(242, 241, 233, 0) 100%
    );
  }
}

.home__highlights {
  padding: 12rem 0;
  user-select: none;
  min-height: 72rem;

  @include media("<=tablet") {
    padding: 120px 24px;
    min-height: unset;
  }

  &__title {
    @extend %title-h15;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 8rem;
  }

  &__list {
    display: inline-flex;
    flex-wrap: nowrap;

    @include media("<=tablet") {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
  }

  &__item {
    width: 32.8rem;
    min-width: 300px;
    margin-right: 20px;
    position: relative;
    z-index: 1;

    @include media("<=tablet") {
      width: 100%;
      margin-right: 0;
      min-width: unset;
    }

    @media (any-pointer: fine) {
      &:hover {
        z-index: 0;
        .home__highlights__circle {
          transform: scale(1) translate(-50%, -50%);
          opacity: 1;
        }
        .home__highlights__link__description {
          color: $grey-200;
        }

        .home__highlights__link__media {
          filter: drop-shadow(0px 16px 24px rgba(38, 38, 35, 0.25));
          transform: translateY(-1rem);
        }

        .c-video__cover {
          transform: scale(1.05);
        }
      }
    }
  }

  &__circle {
    position: absolute;
    background: $yellow;
    width: 48rem;
    height: 48rem;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transition: transform 0.4s $ease-out-expo;
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: left top;
    z-index: -1;
    pointer-events: none;
  }

  &__link {
    &__media {
      width: 100%;
      height: 24.6rem;
      min-height: 240px;
      overflow: hidden;
      border: 1px solid rgba(38, 38, 35, 0.25);
      border-radius: 8px;
      transition: box-shadow 0.4s ease, transform 0.4s ease;

      .c-video__cover {
        transition: transform 0.4s ease-out;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &__content {
      margin-top: 12px;
      text-align: center;
    }

    &__description {
      transition: color 0.4s;
    }

    &__title {
      @extend %font-buttons;
      margin-bottom: 4px;
    }
  }
}

.home__category {
  z-index: 1;
  position: relative;
}

.home__about {
  padding-left: 15.5rem;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;

  @include media("<=1200px") {
    padding-left: 5rem;
  }

  @include media("<=tablet") {
    padding: 0 24px;
  }

  @include media("<=phone") {
    padding: 0;
    margin-bottom: 48px;
  }

  &__left {
    max-width: 557px;
    width: 100%;
    position: relative;
    z-index: 1;

    @include media(">tablet") {
      // padding-top: 12rem;
      padding-top: 6rem;
    }
  }

  &__intro {
    display: flex;
    margin-bottom: 48px;

    @include media("<=phone") {
      padding: 0 24px;
    }

    &__image {
      width: 96px;
      height: 96px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      @extend %title-h15;
      margin-left: 16px;
    }
  }

  &__desc {
    margin-bottom: 48px;

    @include media("<=phone") {
      padding: 0 24px;
    }
    p {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  &__email {
    @extend %title-h1;

    @include media("<=phone") {
      display: flex;
      width: fit-content;
      flex-wrap: nowrap;
      will-change: transform;
      animation: marquee 5s linear infinite;
    }

    @keyframes marquee {
      100% {
        transform: translate3d(-50%, 0, 0);
      }
    }

    span {
      display: inline-block;
      &.show-mobile {
        @extend %mobile;
        margin-left: 10px;
      }
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    color: $grey-200;
    margin-bottom: 2rem;

    @include media("<=phone") {
      padding: 0 24px;
    }
    .c-button {
      @extend %button-hover;

      &:first-of-type {
        margin-right: 12px;
      }
    }
  }

  &__right {
    width: 61rem;
    height: 61rem;
    // transform: translate(4.5rem, 3.85rem);
    transform: translate(0, 2rem);
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media("<=tablet") {
      display: none;
    }
  }
}
