.notfound, .home__wrapper, .home, .brain__cover, .transition-loader, .transition-modal, .transition {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  font-family: inherit;
}

a {
  text-decoration: none;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  font-size: 0.6944444444vw;
  height: 100%;
  left: 0;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

@media (max-width: 768px) {
  html {
    font-size: 1.3020833333vw;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 2.3529411765vw;
  }
}

@media (max-width: 375px) {
  html {
    font-size: 2.6666666667vw;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f2f0e9;
  color: #73716a;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

[data-animation=paragraph] .word {
  overflow: hidden;
  vertical-align: top;
  display: inline-flex;
  padding-bottom: 0.2ch;
  margin-top: -0.2ch;
}

[data-animation=paragraph] .word span {
  display: inline-block;
}

button {
  cursor: pointer;
}

canvas {
  pointer-events: none;
}

video {
  max-width: 100%;
}

img {
  pointer-events: none;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi Var";
  src: url("Satoshi-Variable.f6ec8385.woff2") format("woff2"), url("Satoshi-Variable.98597179.woff") format("woff");
  font-display: swap;
  font-style: normal;
}

.notfound__title, .case__title, .home__about__email, .home__nav__title, .home__about__intro__title, .home__highlights__title, .c-category__left__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -2.4px;
  color: #262623;
}

@media (max-width: 768px) {
  .notfound__title, .case__title, .home__about__email, .home__nav__title, .home__about__intro__title, .home__highlights__title, .c-category__left__title {
    font-size: 40px;
    letter-spacing: -2px;
  }
}

.home__about__intro__title, .home__highlights__title, .c-category__left__title {
  font-weight: 400;
}

.notfound__desc, .notfound__content__header, .case__panel__desc, .cases .home__nav__link, .cases .home__nav__subtitle, .home__highlights__link__title, .home__nav__link__parent button, .c-modal p, .c-footer, .c-category__bubble__title, .notfound .c-button, .case__panel .c-button, .home__about__buttons .c-button, .c-video__play__button span {
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #262623;
}

.case__desc, .home__nav__subtitle {
  line-height: 1.25;
  letter-spacing: -3.5%;
  letter-spacing: -0.56px;
}

.c-footer a, .c-footer p a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

@media (any-pointer: fine) {
  .c-footer a:after {
    background: currentColor;
    opacity: 0.5;
    bottom: 0;
    content: "";
    height: 1.5px;
    left: 0;
    position: absolute;
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    width: 100%;
  }
}

.c-footer a:hover:after, .c-footer p a:after {
  transform: scaleX(1);
  transform-origin: left center;
}

.c-footer a:after, .c-footer p a:hover:after {
  transform: scaleX(0);
  transform-origin: right center;
}

.c-footer p a {
  display: inline-block;
}

.c-footer a {
  display: inline-block;
}

.c-video__play__button span {
  background: #fec228;
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

@media (min-width: 769px) {
  .c-video__play__button span {
    width: 120px;
    height: 120px;
  }
}

.notfound .c-button, .case__panel .c-button, .home__about__buttons .c-button {
  display: inline-block;
  background: transparent;
  color: inherit;
  padding: 16px;
  border: 1.5px solid rgba(38, 38, 35, 0.1);
  border-radius: 39px;
  cursor: pointer;
  transition: all 0.3s;
}

.notfound .c-button .c-link, .case__panel .c-button .c-link, .home__about__buttons .c-button .c-link {
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.notfound .c-button .c-link__inner, .case__panel .c-button .c-link__inner, .home__about__buttons .c-button .c-link__inner {
  display: flex;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-right: 2px;
}

.notfound .c-button .c-link__inner > span, .case__panel .c-button .c-link__inner > span, .home__about__buttons .c-button .c-link__inner > span {
  /* white-space: nowrap; */
}

.notfound .c-button .c-link__animated, .case__panel .c-button .c-link__animated, .home__about__buttons .c-button .c-link__animated {
  /* white-space: nowrap; */
  position: absolute;
  top: 100%;
  display: none;
}

@media (any-pointer: fine) {
  .notfound .c-button .c-link__animated, .case__panel .c-button .c-link__animated, .home__about__buttons .c-button .c-link__animated {
    display: inline-block;
  }
}

.notfound .white.c-button, .case__panel .white.c-button, .home__about__buttons .white.c-button {
  border: 1.5px solid rgba(255, 255, 255, 0.1);
}

@media (any-pointer: fine) {
  .notfound .c-button:hover, .case__panel .c-button:hover, .home__about__buttons .c-button:hover {
    border-color: rgba(38, 38, 35, 0.35);
  }
  .notfound .c-button:hover.white, .case__panel .c-button:hover.white, .home__about__buttons .c-button:hover.white {
    border-color: #ffffff;
  }
  .notfound .c-button:hover .c-link__inner, .case__panel .c-button:hover .c-link__inner, .home__about__buttons .c-button:hover .c-link__inner {
    transform: translateY(-100%);
  }
}

.notfound, .cases, .home {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  width: 100%;
  z-index: 2;
}

.notfound--active, .cases--active, .home--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
}

.case {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

@media (max-width: 768px) {
  .case .show-desktop, .home__nav__link__parent.show-desktop, .c-footer .show-desktop {
    display: none;
  }
}

@media (min-width: 769px) {
  .case .show-mobile, .home__nav__buttons.show-mobile, .c-footer .show-mobile {
    display: none;
  }
}

@media (min-width: 426px) {
  .home__about__email span.show-mobile {
    display: none;
  }
}

.c-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.c-video iframe {
  width: 100%;
  height: 100%;
}

.c-video__cover, .c-video__cover img, .c-video__cover video,
.c-video #yt-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-video__cover img,
.c-video__cover video {
  object-fit: cover;
}

.c-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-video__play__button {
  padding: 4rem;
}

.c-category {
  align-items: center;
  padding-left: 15.5rem;
  position: relative;
  display: flex;
  height: 72rem;
}

@media (max-width: 1200px) {
  .c-category {
    padding-left: 5rem;
  }
}

@media (max-width: 768px) {
  .c-category {
    padding: 0 24px;
    display: block;
    height: auto;
  }
}

@media (max-width: 425px) {
  .c-category {
    padding: 0;
  }
}

.c-category__left {
  max-width: 328px;
  width: 100%;
  margin-right: 8.6rem;
  color: #888888;
  mix-blend-mode: difference;
}

.c-category__left__title {
  margin-bottom: 24px;
  color: #888888;
}

@media (max-width: 768px) {
  .c-category__left {
    margin-bottom: 40px;
  }
}

@media (max-width: 425px) {
  .c-category__left {
    padding: 0 24px;
  }
}

.c-category__right {
  width: 100%;
}

@media (max-width: 768px) {
  .c-category__bubbles {
    display: flex;
    margin-bottom: 16px;
  }
}

@media (max-width: 425px) {
  .c-category__bubbles {
    justify-content: flex-end;
  }
}

.c-category__bubble {
  border: 1.5px solid rgba(38, 38, 35, 0.1);
  border-radius: 50%;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  width: 140px;
  height: 140px;
  position: absolute;
  transition: transform 0.3s;
}

@media (min-width: 769px) {
  .c-category__bubble:hover {
    transform: scale(1.05);
    border: 0;
  }
}

.c-category__bubble img,
.c-category__bubble video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .c-category__bubble img,
.c-category__bubble video {
    display: none !important;
  }
}

@media (any-pointer: fine) {
  .c-category__bubble img,
.c-category__bubble video {
    display: block;
  }
}

.c-category__bubble__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  transition: opacity 0.3s;
  z-index: 2;
}

.c-category__bubble--life-drawings {
  width: 16.3rem;
  height: 16.3rem;
  left: 64.7rem;
  top: 15rem;
}

@media (max-width: 425px) {
  .c-category__bubble--life-drawings {
    transform: translate(-0.9rem, -3.5rem);
  }
}

.c-category__bubble--storyboarding {
  width: 21.4rem;
  height: 21.4rem;
  left: 82rem;
  top: 6.4rem;
}

@media (max-width: 425px) {
  .c-category__bubble--storyboarding {
    transform: translate(-2.3rem, 0.1rem);
  }
}

.c-category__bubble--rigged-animation {
  width: 16.9rem;
  height: 16.9rem;
  right: 21.5rem;
  top: 8rem;
}

@media (max-width: 425px) {
  .c-category__bubble--rigged-animation {
    margin-right: 16px !important;
  }
}

.c-category__bubble--early-work {
  width: 13.5rem;
  height: 13.5rem;
  top: 6.4rem;
  right: 5.8rem;
}

.c-category__bubble--comics {
  width: 16.6rem;
  height: 16.6rem;
  left: 56.9rem;
  top: 31.3rem;
}

@media (max-width: 425px) {
  .c-category__bubble--comics {
    transform: translate(-8.6rem, -6rem);
  }
}

.c-category__bubble--character-design {
  width: 16.8rem;
  height: 16.8rem;
  left: 77.7rem;
  top: 28.2rem;
}

@media (max-width: 425px) {
  .c-category__bubble--character-design {
    transform: translate(0.6rem, -8.4rem);
  }
}

.c-category__bubble--background-design {
  width: 18.3rem;
  height: 18.3rem;
  right: 29rem;
  top: 25.7rem;
}

@media (max-width: 425px) {
  .c-category__bubble--background-design {
    transform: translate(-1.5rem, -6rem);
  }
}

.c-category__bubble--compositions {
  width: 21rem;
  height: 21rem;
  right: 4.4rem;
  top: 21.7rem;
}

@media (max-width: 425px) {
  .c-category__bubble--compositions {
    transform: translate(-0.7rem, -8.4rem);
  }
}

.c-category__bubble--fx-animation {
  width: 20.8rem;
  height: 20.8rem;
  left: 67.5rem;
  bottom: 5.8rem;
}

@media (max-width: 425px) {
  .c-category__bubble--fx-animation {
    transform: translate(0.4rem, -5.5rem);
  }
}

.c-category__bubble--hand-drawn {
  width: 18.7rem;
  height: 18.7rem;
  left: 89.9rem;
  bottom: 8.8rem;
}

@media (max-width: 425px) {
  .c-category__bubble--hand-drawn {
    transform: translate(1.6rem, -12.8rem);
  }
}

.c-category__bubble--films {
  width: 14.9rem;
  height: 14.9rem;
  right: 19.3rem;
  bottom: 15rem;
}

@media (max-width: 425px) {
  .c-category__bubble--films {
    transform: translate(-7rem, -10.6rem);
  }
}

.c-category__bubble--props-design {
  width: 14.6rem;
  height: 14.6rem;
  right: 2.7rem;
  bottom: 13.4rem;
}

@media (max-width: 425px) {
  .c-category__bubble--props-design {
    transform: translate(-1.4rem, 0);
  }
}

@media (max-width: 768px) {
  .c-category__bubble {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .c-category__bubble:first-child {
    margin-right: 30px;
  }
}

@media (max-width: 425px) {
  .c-category__bubble:first-child {
    margin-right: 0;
  }
}

.c-category__bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f0e9;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  opacity: 1;
  z-index: 1;
  border-radius: 50%;
}

@media (any-pointer: fine) {
  .c-category__bubble:hover::after {
    opacity: 0;
  }
  .c-category__bubble:hover .c-category__bubble__title {
    opacity: 0;
  }
}

.c-footer {
  padding: 40px 15.5rem;
  border-top: 1.5px solid rgba(38, 38, 35, 0.1);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .c-footer {
    padding: 40px 5rem;
  }
}

@media (max-width: 768px) {
  .c-footer {
    padding: 48px 24px;
    max-width: 500px;
    margin: 0 auto;
  }
}

.c-footer__gif {
  margin-right: 4.4rem;
  width: 96px;
  height: 96px;
}

.c-footer__gif img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-footer a {
  padding-bottom: 2px;
}

.c-footer__left {
  display: flex;
}

@media (max-width: 768px) {
  .c-footer__left {
    flex-direction: column;
  }
}

.c-footer__left > p {
  margin-right: 4rem;
}

@media (max-width: 768px) {
  .c-footer__left > p {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.c-footer__left > a:not(:last-of-type) {
  margin-right: 4rem;
}

@media (max-width: 768px) {
  .c-footer__left > a {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 7;
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  will-change: opacity;
  background: #f2f0e9;
}

.c-modal svg {
  color: #262623;
}

.c-modal.active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.c-modal__icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: grid;
  place-items: center;
  position: absolute;
  border: 1.5px solid rgba(38, 38, 35, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.c-modal__icon:hover {
  border-color: rgba(38, 38, 35, 0.35);
}

.c-modal__arrow {
  top: 50%;
  transform: translateY(-50%);
  background: #f2f0e9;
}

.c-modal__arrow--left {
  left: 40px;
}

@media (max-width: 768px) {
  .c-modal__arrow--left {
    left: 8px;
  }
}

.c-modal__arrow--right {
  right: 40px;
}

@media (max-width: 768px) {
  .c-modal__arrow--right {
    right: 8px;
  }
}

.c-modal__close {
  top: 40px;
  right: 40px;
}

@media (max-width: 768px) {
  .c-modal__close {
    right: 20px;
  }
}

.c-modal__owner {
  left: 40px;
}

@media (max-width: 768px) {
  .c-modal__owner {
    left: 20px;
  }
}

.c-modal__title {
  right: 40px;
  color: #73716a;
}

@media (max-width: 768px) {
  .c-modal__title {
    right: 20px;
  }
}

.c-modal p {
  position: absolute;
  bottom: 40px;
}

.c-modal__video {
  width: 100%;
  height: 100%;
  padding-left: 15.8rem;
  padding-right: 15.8rem;
  padding-top: 5rem;
  max-width: 1300px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .c-modal__video {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .c-modal__video .c-video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-modal--thumbnail__images {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-modal--thumbnail__image {
  grid-area: 1/1;
  opacity: 0;
  transition: opacity 0.7s ease-out;
}

.c-modal--thumbnail__image.active {
  opacity: 1;
}

.c-modal--thumbnail__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-modal--thumbnail .c-modal__video {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .c-modal--thumbnail .c-modal__video {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.c-modal--thumbnail .c-modal__video .c-video {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .c-modal--thumbnail .c-modal__video .c-video {
    position: relative;
  }
}

.transition {
  z-index: 8;
}

.transition-modal {
  z-index: 6;
}

.transition-loader {
  z-index: 10;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: #fec228;
  padding: 40px;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
}

@media (max-width: 768px) {
  .preloader {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.preloader__wrapper {
  margin-top: auto;
  width: 100%;
}

.preloader__name {
  font-size: 20.2rem;
  font-weight: 700;
  letter-spacing: -1.01rem;
  margin-bottom: 8px;
  color: #262623;
}

@media (max-width: 768px) {
  .preloader__name {
    font-size: 10rem;
    letter-spacing: -0.5rem;
  }
}

@media (max-width: 425px) {
  .preloader__name {
    font-size: 4.5rem;
    letter-spacing: -0.225rem;
  }
}

.preloader__bottom {
  display: flex;
  font-size: 32px;
  letter-spacing: -1.6px;
  color: #262623;
  line-height: 1;
}

@media (max-width: 768px) {
  .preloader__bottom {
    font-size: 24px;
  }
}

.preloader__desc {
  max-width: 91.7rem;
  width: 100%;
  flex-shrink: 0;
}

.preloader__number {
  display: inline-block;
}

@media (max-width: 768px) {
  .preloader__number {
    margin-left: 0;
  }
}

.preloader__number__wrapper {
  width: 100%;
}

@media (max-width: 768px) {
  .preloader__number__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 48px);
    margin: 0 auto;
  }
}

.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: 5;
  }
}

@media (any-pointer: fine) and (max-width: 768px) {
  .cursor {
    display: none;
  }
}

@media (any-pointer: fine) {
  .cursor__inner {
    fill: #fec228;
    stroke: none;
    stroke-width: 1px;
    opacity: 1;
  }
}

@media (any-pointer: fine) {
  .no-js .cursor {
    display: none;
  }
}

.brain__cover {
  position: fixed;
  pointer-events: none;
  z-index: 9;
  display: none;
}

@media (any-pointer: fine) {
  .brain__cover {
    display: block;
  }
}

@media (max-width: 768px) {
  .brain__cover {
    display: none;
  }
}

.brain__wrapper {
  position: absolute;
  right: 40px;
  bottom: 40px;
  animation: float 6s ease-in-out infinite;
}

.brain--footer__wrapper {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.home {
  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.home__wrapper {
  height: auto;
  will-change: transform;
}

.home__wrapper .c-video__play {
  z-index: 1;
}

.home__wrapper .c-video--home {
  z-index: -1;
}

@media (min-width: 769px) {
  .home__wrapper .c-video--home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(38, 38, 35, 0.75) 0%, rgba(38, 38, 35, 0) 100%);
    z-index: 1;
    pointer-events: none;
  }
}

.home__nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .home__nav {
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .home__nav {
    position: absolute;
    align-items: flex-start;
    color: #ffffff;
  }
}

.home__nav__buttons {
  display: flex;
  width: 100%;
}

.home__nav__link__parent {
  width: 100%;
  max-width: 17.9rem;
  border-right: 1.5px solid rgba(38, 38, 35, 0.1);
  display: grid;
  place-items: center;
}

.home__nav__link__parent.show-desktop.second {
  border-right: 0;
  border-left: 1.5px solid rgba(38, 38, 35, 0.1);
}

@media (max-width: 768px) {
  .home__nav__link__parent {
    max-width: 50%;
    width: 100%;
    border-top: 1.5px solid rgba(38, 38, 35, 0.1);
  }
}

.home__nav__link__parent button {
  position: relative;
  background: transparent;
}

.home__nav__link__parent button span {
  padding: 4.8rem;
  display: inline-block;
  position: relative;
  z-index: 3;
}

@media (max-width: 768px) {
  .home__nav__link__parent button {
    padding: 24px 0;
  }
}

@media (min-width: 769px) {
  .home__nav__link__parent button {
    color: #ffffff;
  }
}

.home__nav__content {
  padding: 4.8rem 0;
  text-align: center;
}

.home__nav__title {
  margin-bottom: 4px;
}

@media (min-width: 769px) {
  .home__nav__title {
    color: #ffffff;
  }
}

.home__bottom {
  background: #f6f2ea;
  position: relative;
}

@media (max-width: 768px) {
  .home__bottom {
    background: none;
  }
  .home__bottom::before {
    background: none;
  }
}

.home__bottom::after {
  content: "";
  position: absolute;
  height: 90rem;
  width: 100%;
  top: -6rem;
  background: linear-gradient(180deg, #f2f1e9 0%, rgba(242, 241, 233, 0) 100%);
}

.home__highlights {
  padding: 12rem 0;
  user-select: none;
  min-height: 72rem;
}

@media (max-width: 768px) {
  .home__highlights {
    padding: 120px 24px;
    min-height: unset;
  }
}

.home__highlights__title {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 8rem;
}

.home__highlights__list {
  display: inline-flex;
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .home__highlights__list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

.home__highlights__item {
  width: 32.8rem;
  min-width: 300px;
  margin-right: 20px;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .home__highlights__item {
    width: 100%;
    margin-right: 0;
    min-width: unset;
  }
}

@media (any-pointer: fine) {
  .home__highlights__item:hover {
    z-index: 0;
  }
  .home__highlights__item:hover .home__highlights__circle {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  .home__highlights__item:hover .home__highlights__link__description {
    color: #262623;
  }
  .home__highlights__item:hover .home__highlights__link__media {
    filter: drop-shadow(0px 16px 24px rgba(38, 38, 35, 0.25));
    transform: translateY(-1rem);
  }
  .home__highlights__item:hover .c-video__cover {
    transform: scale(1.05);
  }
}

.home__highlights__circle {
  position: absolute;
  background: #fec228;
  width: 48rem;
  height: 48rem;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: left top;
  z-index: -1;
  pointer-events: none;
}

.home__highlights__link__media {
  width: 100%;
  height: 24.6rem;
  min-height: 240px;
  overflow: hidden;
  border: 1px solid rgba(38, 38, 35, 0.25);
  border-radius: 8px;
  transition: box-shadow 0.4s ease, transform 0.4s ease;
}

.home__highlights__link__media .c-video__cover {
  transition: transform 0.4s ease-out;
}

.home__highlights__link__media img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.home__highlights__link__content {
  margin-top: 12px;
  text-align: center;
}

.home__highlights__link__description {
  transition: color 0.4s;
}

.home__highlights__link__title {
  margin-bottom: 4px;
}

.home__category {
  z-index: 1;
  position: relative;
}

.home__about {
  padding-left: 15.5rem;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .home__about {
    padding-left: 5rem;
  }
}

@media (max-width: 768px) {
  .home__about {
    padding: 0 24px;
  }
}

@media (max-width: 425px) {
  .home__about {
    padding: 0;
    margin-bottom: 48px;
  }
}

.home__about__left {
  max-width: 557px;
  width: 100%;
  position: relative;
  z-index: 1;
}

@media (min-width: 769px) {
  .home__about__left {
    padding-top: 6rem;
  }
}

.home__about__intro {
  display: flex;
  margin-bottom: 48px;
}

@media (max-width: 425px) {
  .home__about__intro {
    padding: 0 24px;
  }
}

.home__about__intro__image {
  width: 96px;
  height: 96px;
}

.home__about__intro__image img {
  width: 100%;
  height: 100%;
}

.home__about__intro__title {
  margin-left: 16px;
}

.home__about__desc {
  margin-bottom: 48px;
}

@media (max-width: 425px) {
  .home__about__desc {
    padding: 0 24px;
  }
}

.home__about__desc p:not(:last-of-type) {
  margin-bottom: 16px;
}

@media (max-width: 425px) {
  .home__about__email {
    display: flex;
    width: fit-content;
    flex-wrap: nowrap;
    will-change: transform;
    animation: marquee 5s linear infinite;
  }
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.home__about__email span {
  display: inline-block;
}

.home__about__email span.show-mobile {
  margin-left: 10px;
}

.home__about__buttons {
  margin-top: 16px;
  display: flex;
  color: #262623;
  margin-bottom: 2rem;
}

@media (max-width: 425px) {
  .home__about__buttons {
    padding: 0 24px;
  }
}

.home__about__buttons .c-button:first-of-type {
  margin-right: 12px;
}

.home__about__right {
  width: 61rem;
  height: 61rem;
  transform: translate(0, 2rem);
}

.home__about__right video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .home__about__right {
    display: none;
  }
}

.cases .home__nav {
  border-bottom: 1.5px solid rgba(38, 38, 35, 0.1);
  background: #f2f0e9;
  transition: background 0.3s ease;
}

@media (max-width: 768px) {
  .cases .home__nav {
    flex-direction: row;
  }
}

.cases .home__nav.no-bg {
  background: transparent;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
}

.cases .home__nav.no-bg .home__nav__link {
  color: #ffffff;
}

.cases .home__nav.no-bg .home__nav__link__parent {
  border-right: 1.5px solid rgba(255, 255, 255, 0.1);
}

.cases .home__nav.no-bg .home__nav__link__parent.second {
  border-left: 1.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0;
}

.cases .home__nav.no-bg .home__nav__subtitle {
  color: #ffffff;
}

.cases .home__nav__subtitle {
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s ease;
}

@media (max-width: 600px) {
  .cases .home__nav__subtitle span {
    display: none;
  }
}

.cases .home__nav__link {
  height: 100%;
  width: 100%;
  padding: 24px 4rem;
  display: grid;
  place-items: center;
  transition: color 0.3s ease;
}

@media (max-width: 600px) {
  .cases .home__nav__link {
    padding: 24px;
  }
}

@media (min-width: 601px) {
  .cases .home__nav__link .icon {
    display: none;
    margin-right: 8px;
    margin-top: 2.5px;
  }
}

.cases .home__nav__link .icon.show {
  display: inline;
}

@media (max-width: 600px) {
  .cases .home__nav__link .text {
    display: none;
  }
}

.cases .home__nav__link .magnetic-el {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cases .home__nav__link span {
  padding: 0;
}

.cases .home__nav__link__parent {
  max-width: unset;
  padding: 0;
  width: unset;
  flex-shrink: 0;
}

.cases .home__nav__link__parent.second {
  border-left: 1.5px solid rgba(38, 38, 35, 0.1);
  border-right: 0;
}

.cases .home__nav__content {
  padding: 0;
}

.case {
  will-change: transform;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
}

.case__bottom {
  position: absolute;
  bottom: 0;
}

.case--active {
  opacity: 1;
  transition: none;
  visibility: visible;
}

.case__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 1;
}

.case__nav--active {
  display: flex;
}

.case__title {
  margin-bottom: 16px;
  text-align: center;
}

.case__desc {
  max-width: 442px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 32px;
}

.case__media__parent {
  margin-bottom: 16rem;
}

@media (max-width: 425px) {
  .case__media__parent {
    margin-bottom: 12rem;
  }
}

.case__reel__gallery__media {
  border-radius: 8px;
  overflow: hidden;
}

.case__wrapper {
  max-width: 90.2rem;
  width: 100%;
  margin: 0 auto;
}

.case__wrapper--grid {
  margin-bottom: 22rem;
}

.case__content {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 14.5rem;
}

@media (max-width: 425px) {
  .case__content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.case__footer {
  width: 100%;
  height: 162rem;
  position: relative;
}

.case__footer__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.case__footer__image img,
.case__footer__image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case__footer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90rem;
  background: linear-gradient(180deg, #f2f1e9 0%, rgba(242, 241, 233, 0) 100%);
  z-index: 1;
}

.case__footer__category {
  position: absolute;
  top: -6rem;
  left: 0;
  width: 100%;
  z-index: 2;
}

.case__footer__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 4rem;
  padding-right: 4rem;
}

.case__footer__bottom__wrapper {
  margin-top: auto;
}

.case__footer__bottom__wrapper span {
  color: #ffffff;
}

.case__footer__bottom__wrapper .home__about__buttons {
  margin-bottom: 4rem;
}

.case__footer__bottom__wrapper .home__about__buttons .c-button {
  border: 1.5px solid rgba(255, 255, 255, 0.1);
}

@media (any-pointer: fine) {
  .case__footer__bottom__wrapper .home__about__buttons .c-button:hover {
    border-color: #ffffff;
  }
}

.case__footer__bottom .c-footer {
  color: #ffffff;
  padding-left: 0;
  padding-right: 0;
  border-top: 1.5px solid rgba(255, 255, 255, 0.1);
}

.case__panel {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1.5px solid rgba(38, 38, 35, 0.1);
  border-width: 2px;
}

.case__panel .c-button {
  color: #262623;
}

.case__grid {
  display: grid;
  grid-gap: 16px;
}

.case__grid--half {
  grid-template-columns: repeat(2, 1fr);
}

.case__grid--half .grid-item:before {
  padding-top: 100%;
}

.case__grid--twelve {
  grid-template-columns: repeat(8, 1fr);
}

.case__grid--twelve .grid-item:nth-child(1) {
  grid-column: span 3;
}

.case__grid--twelve .grid-item:nth-child(2) {
  grid-column: span 5;
}

.case__grid--twelve .grid-item:before {
  padding-top: 58%;
}

.case__grid .grid-item {
  position: relative;
  width: 100%;
}

.case__grid .grid-item:before {
  content: "";
  height: 0;
  display: inline-block;
  width: 1px;
  position: relative;
}

.case__grid .grid-item .content,
.case__grid .grid-item img,
.case__grid .grid-item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case__grid .grid-item-half:before {
  padding-top: 100%;
}

.case__grid .grid-item-twelve:before {
  padding-top: 100%;
}

.case__grid__content:not(:last-of-type) {
  margin-bottom: 16px;
}

.notfound__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.notfound__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notfound__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.notfound__content__header {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
}

.notfound__content__header a,
.notfound__content__header p {
  padding: 24px 4rem;
}

@media (max-width: 768px) {
  .notfound__content__header a,
.notfound__content__header p {
    max-width: 50%;
    width: 100%;
    padding: 24px 0;
    text-align: center;
  }
}

.notfound__content__header a {
  border-left: 1.5px solid rgba(255, 255, 255, 0.1);
  display: block;
}

.notfound__content__inner {
  margin-top: auto;
  padding: 0 4rem;
}

@media (max-width: 768px) {
  .notfound__content__inner {
    padding: 0 24px;
  }
}

.notfound__title {
  margin-bottom: 16px;
  color: #ffffff;
}

.notfound__desc {
  margin-bottom: 24px;
  color: #ffffff;
}

.notfound .c-button {
  color: #ffffff;
  margin-bottom: 56px;
}

@media (max-width: 768px) {
  .notfound .c-button {
    margin-bottom: 48px;
  }
}

.notfound .c-footer {
  color: #ffffff;
  padding-left: 0;
  padding-right: 0;
  border-top: 1.5px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .notfound .c-footer {
    display: none;
  }
}
/*# sourceMappingURL=index.68a7a377.css.map */
