.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: z("cursor");

    @include media("<=tablet") {
      display: none;
    }
  }

  .cursor__inner {
    fill: $yellow;
    stroke: none;
    stroke-width: 1px;
    opacity: 1;
  }

  .no-js .cursor {
    display: none;
  }
}
