.transition {
  @extend %cover;

  z-index: z("transition");
}

.transition-modal {
  @extend %cover;

  z-index: z("modal-transition");
}

.transition-loader {
  @extend %cover;

  z-index: z("loader-transition");
}
