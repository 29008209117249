%title-h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: calc(-0.05 * 48px);
  color: $grey-200;

  @include media("<=tablet") {
    font-size: 40px;
    letter-spacing: calc(-0.05 * 40px);
  }
}

%title-h15 {
  @extend %title-h1;
  font-weight: 400;
}

%font-buttons {
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  letter-spacing: calc(-0.025 * 16px);
  color: $grey-200;
}

%subtitles {
  line-height: 1.25;
  letter-spacing: -3.5%;
  letter-spacing: calc(-0.035 * 16px);
}
