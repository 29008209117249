.cases {
  @extend %page;

  &--active {
    @extend %page--active;
  }

  .home__nav {
    border-bottom: $border-gray;
    background: $main-bg;
    transition: background 0.3s ease;
    @include media("<=tablet") {
      flex-direction: row;
    }

    &.no-bg {
      background: transparent;
      border-bottom: $border-white;

      .home__nav__link {
        color: $white;
        &__parent {
          border-right: $border-white;

          &.second {
            border-left: $border-white;
            border-right: 0;
          }
        }
      }
      .home__nav__subtitle {
        color: $white;
      }
    }

    &__subtitle {
      @extend %font-buttons;
      padding: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color 0.3s ease;

      @include media("<=600px") {
        span {
          display: none;
        }
      }
    }

    &__link {
      height: 100%;
      width: 100%;
      padding: 24px 4rem;
      display: grid;
      place-items: center;
      @extend %font-buttons;
      transition: color 0.3s ease;

      @include media("<=600px") {
        padding: 24px;
      }

      .icon {
        @include media(">600px") {
          display: none;
          margin-right: 8px;
          margin-top: 2.5px;
        }

        &.show {
          display: inline;
        }
      }

      .text {
        @include media("<=600px") {
          display: none;
        }
      }

      .magnetic-el {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        padding: 0;
      }
      &__parent {
        max-width: unset;
        padding: 0;
        width: unset;
        flex-shrink: 0;
        &.second {
          border-left: $border-gray;
          border-right: 0;
        }
      }
    }

    &__content {
      padding: 0;
    }
  }
}

.case {
  @extend %content;
  will-change: transform;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;

  &__bottom {
    position: absolute;
    bottom: 0;
  }

  .show-desktop {
    @extend %desktop-tab;
  }

  .show-mobile {
    @extend %tablet;
  }

  &--active {
    opacity: 1;
    transition: none;
    visibility: visible;
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 1;
    // // z-index: z("navigation");

    &--active {
      display: flex;
    }
  }

  &__title {
    @extend %title-h1;
    margin-bottom: 16px;
    text-align: center;
  }

  &__desc {
    @extend %subtitles;
    max-width: 442px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 32px;
  }
}

.case__media__parent {
  margin-bottom: 16rem;

  @include media("<=phone") {
    margin-bottom: 12rem;
  }
}

.case__reel__gallery {
  &__media {
    border-radius: 8px;
    overflow: hidden;
  }
}

// reusables
.case__wrapper {
  max-width: 90.2rem;
  width: 100%;
  margin: 0 auto;

  &--grid {
    margin-bottom: 22rem;
  }
}

.case__content {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 14.5rem;

  @include media("<=phone") {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.case__footer {
  width: 100%;
  height: 162rem;
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90rem;
    // background: var(--footer-gradient);
    background: linear-gradient(
      180deg,
      #f2f1e9 0%,
      rgba(242, 241, 233, 0) 100%
    );
    z-index: 1;
  }

  &__category {
    position: absolute;
    top: -6rem;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 4rem;
    padding-right: 4rem;

    &__wrapper {
      margin-top: auto;

      span {
        color: $white;
      }
      .home__about__buttons {
        margin-bottom: 4rem;

        .c-button {
          border: $border-white;

          &:hover {
            @media (any-pointer: fine) {
              border-color: $white;
            }
          }
        }
      }
    }

    .c-footer {
      color: $white;
      padding-left: 0;
      padding-right: 0;
      border-top: $border-white;
    }
  }
}

.case__panel {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: $border-gray;
  border-width: 2px;

  &__desc {
    @extend %font-buttons;
  }

  .c-button {
    @extend %button-hover;
    color: $grey-200;
  }
}

.case-full {
}

.case__grid {
  display: grid;
  grid-gap: 16px;
  &--half {
    grid-template-columns: repeat(2, 1fr);

    .grid-item {
      &:before {
        padding-top: 100%;
      }
    }
  }

  &--twelve {
    grid-template-columns: repeat(8, 1fr);
    //  @include media(">phone") {
    //  }

    .grid-item {
      // @include media(">phone") {
      &:nth-child(1) {
        grid-column: span 3;
      }
      &:nth-child(2) {
        grid-column: span 5;
      }
      // }

      &:before {
        padding-top: 58%;
      }
    }
  }

  .grid-item {
    position: relative;
    width: 100%;

    &:before {
      content: "";
      height: 0;
      display: inline-block;
      width: 1px;
      position: relative;
    }

    .content,
    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .grid-item-half {
    &:before {
      padding-top: 100%;
    }
  }

  .grid-item-twelve {
    &:before {
      padding-top: 100%;
    }
  }

  &__content {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

// (style=`--footer-gradient: ${footerGradient}`)
