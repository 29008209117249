.notfound {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      color: $white;
      border-bottom: $border-white;
      @extend %font-buttons;

      a,
      p {
        padding: 24px 4rem;

        @include media("<=tablet") {
          max-width: 50%;
          width: 100%;
          padding: 24px 0;
          text-align: center;
        }
      }

      a {
        border-left: $border-white;
        display: block;
      }
    }

    &__inner {
      margin-top: auto;
      padding: 0 4rem;

      @include media("<=tablet") {
        padding: 0 24px;
      }
    }
  }

  &__title {
    @extend %title-h1;
    margin-bottom: 16px;
    color: $white;
  }

  &__desc {
    @extend %font-buttons;
    margin-bottom: 24px;
    color: $white;
  }

  .c-button {
    @extend %button-hover;
    color: $white;
    margin-bottom: 56px;

    @include media("<=tablet") {
      margin-bottom: 48px;
    }
  }

  .c-footer {
    color: $white;
    padding-left: 0;
    padding-right: 0;
    border-top: $border-white;

    @include media("<=tablet") {
      display: none;
    }
  }
}
