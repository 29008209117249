.c-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    width: 100%;
    height: 100%;
  }

  &__cover,
  &__cover img,
  &__cover video,
  #yt-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__cover {
    img,
    video {
      object-fit: cover;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__button {
      padding: 4rem;

      span {
        @extend %button-play;
      }
    }
  }
}
