.c-footer {
  padding: 40px 15.5rem;
  border-top: $border-gray;
  width: 100%;
  display: flex;
  @extend %font-buttons;
  justify-content: space-between;

  @include media("<=1200px") {
    padding: 40px 5rem;
  }

  @include media("<=tablet") {
    padding: 48px 24px;
    max-width: 500px;
    margin: 0 auto;
  }

  &__gif {
    margin-right: 4.4rem;
    width: 96px;
    height: 96px;
    // width: 120px;
    // height: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a {
    @extend %link--hidden;
    padding-bottom: 2px;
  }

  p {
    a {
      @extend %link;
    }
  }

  .show-desktop {
    @extend %desktop-tab;
  }

  .show-mobile {
    @extend %tablet;
  }

  &__left {
    display: flex;
    @include media("<=tablet") {
      flex-direction: column;
    }

    > p {
      margin-right: 4rem;
      @include media("<=tablet") {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    > a {
      &:not(:last-of-type) {
        margin-right: 4rem;
      }

      @include media("<=tablet") {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }
  }
}
