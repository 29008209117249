%button-play {
  @extend %font-buttons;
  background: $yellow;
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  border-radius: 50%;

  @include media(">tablet") {
    width: 120px;
    height: 120px;
  }
}

%button-hover {
  display: inline-block;
  background: transparent;
  @extend %font-buttons;
  color: inherit;
  padding: 16px;
  border: $border-gray;
  border-radius: 39px;
  cursor: pointer;
  transition: all 0.3s;

  .c-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__inner {
      display: flex;
      transform: translateY(0);
      // transition: transform 0.3s ease-out;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      margin-right: 2px;

      > span {
        /* white-space: nowrap; */
      }
    }

    &__animated {
      /* white-space: nowrap; */
      position: absolute;
      top: 100%;
      display: none;

      @media (any-pointer: fine) {
        display: inline-block;
      }
    }
  }

  &.white {
    border: $border-white;
  }

  &:hover {
    @media (any-pointer: fine) {
      border-color: rgba(38, 38, 35, 0.35);
      &.white {
        border-color: $white;
      }
      .c-link__inner {
        transform: translateY(-100%);
      }
    }
  }
}
