%page {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  width: 100%;
  z-index: z("content");
}

%page--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
}

%content {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

%home-wrapper {
  padding-left: 15.5rem;

  @include media("<=phone") {
    padding-left: 24px;
    padding-right: 24px;
  }
}

%desktop {
  @include media("<=phone") {
    display: none;
  }
}

%desktop-tab {
  @include media("<=tablet") {
    display: none;
  }
}

%tablet {
  @include media(">tablet") {
    display: none;
  }
}

%mobile {
  @include media(">phone") {
    display: none;
  }
}
