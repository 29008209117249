html {
  box-sizing: border-box;
  font-size: calc(100vw / 1440 * 10);
  height: 100%;
  left: 0;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;

  @include media("<=tablet") {
    font-size: calc(100vw / 768 * 10);
  }

  @include media("<=phone") {
    font-size: calc(100vw / 425 * 10);
  }

  @include media("<=375px") {
    font-size: calc(100vw / 375 * 10);
  }
}

// resets
body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI,
    sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: $main-bg;
  color: $grey-100;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

[data-animation="paragraph"] {
  .word {
    overflow: hidden;
    vertical-align: top;
    display: inline-flex;
    padding-bottom: 0.2ch;
    margin-top: -0.2ch;

    span {
      display: inline-block;
    }
  }
}

button {
  cursor: pointer;
}

canvas {
  pointer-events: none;
}

video {
  max-width: 100%;
}

img {
  pointer-events: none;
}
