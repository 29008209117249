.c-category {
  align-items: center;
  padding-left: 15.5rem;
  position: relative;
  display: flex;
  height: 72rem;

  @include media("<=1200px") {
    padding-left: 5rem;
  }

  @include media("<=tablet") {
    padding: 0 24px;
    display: block;
    height: auto;
  }

  @include media("<=phone") {
    padding: 0;
  }

  &__left {
    max-width: 328px;
    width: 100%;
    margin-right: 8.6rem;
    color: #888888;
    mix-blend-mode: difference;

    &__title {
      @extend %title-h15;
      margin-bottom: 24px;
      color: #888888;
    }

    @include media("<=tablet") {
      margin-bottom: 40px;
    }

    @include media("<=phone") {
      padding: 0 24px;
    }
  }

  &__right {
    width: 100%;
  }

  &__bubbles {
    @include media("<=tablet") {
      display: flex;
      margin-bottom: 16px;
    }

    @include media("<=phone") {
      justify-content: flex-end;
    }
  }

  &__bubble {
    border: $border-gray;
    border-radius: 50%;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    position: absolute;
    transition: transform 0.3s;

    // &__overlay {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: rgba(38, 38, 35, 0.35);
    //   width: 100%;
    //   height: 100%;
    // }

    @include media(">tablet") {
      &:hover {
        transform: scale(1.05);
        border: 0;
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;

      @include media("<=tablet") {
        display: none !important;
      }

      @media (any-pointer: fine) {
        display: block;
      }
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-align: center;
      @extend %font-buttons;
      transition: opacity 0.3s;
      z-index: 2;
    }

    &--life-drawings {
      width: 16.3rem;
      height: 16.3rem;
      left: 64.7rem;
      top: 15rem;

      @include media("<=phone") {
        transform: translate(-0.9rem, -3.5rem);
      }
    }

    &--storyboarding {
      width: 21.4rem;
      height: 21.4rem;
      left: 82rem;
      top: 6.4rem;

      @include media("<=phone") {
        transform: translate(-2.3rem, 0.1rem);
      }
    }

    &--rigged-animation {
      width: 16.9rem;
      height: 16.9rem;
      right: 21.5rem;
      top: 8rem;

      @include media("<=phone") {
        margin-right: 16px !important;
      }
    }

    &--early-work {
      width: 13.5rem;
      height: 13.5rem;
      top: 6.4rem;
      right: 5.8rem;
    }

    &--comics {
      width: 16.6rem;
      height: 16.6rem;
      left: 56.9rem;
      top: 31.3rem;

      @include media("<=phone") {
        transform: translate(-8.6rem, -6rem);
      }
    }

    &--character-design {
      width: 16.8rem;
      height: 16.8rem;
      left: 77.7rem;
      top: 28.2rem;

      @include media("<=phone") {
        transform: translate(0.6rem, -8.4rem);
      }
    }

    &--background-design {
      width: 18.3rem;
      height: 18.3rem;
      right: 29rem;
      top: 25.7rem;

      @include media("<=phone") {
        transform: translate(-1.5rem, -6rem);
      }
    }

    &--compositions {
      width: 21rem;
      height: 21rem;
      right: 4.4rem;
      top: 21.7rem;

      @include media("<=phone") {
        transform: translate(-0.7rem, -8.4rem);
      }
    }

    &--fx-animation {
      width: 20.8rem;
      height: 20.8rem;
      left: 67.5rem;
      bottom: 5.8rem;

      @include media("<=phone") {
        transform: translate(0.4rem, -5.5rem);
      }
    }

    &--hand-drawn {
      width: 18.7rem;
      height: 18.7rem;
      left: 89.9rem;
      bottom: 8.8rem;

      @include media("<=phone") {
        transform: translate(1.6rem, -12.8rem);
      }
    }

    &--films {
      width: 14.9rem;
      height: 14.9rem;
      right: 19.3rem;
      bottom: 15rem;

      @include media("<=phone") {
        transform: translate(-7rem, -10.6rem);
      }
    }

    &--props-design {
      width: 14.6rem;
      height: 14.6rem;
      right: 2.7rem;
      bottom: 13.4rem;

      @include media("<=phone") {
        transform: translate(-1.4rem, 0);
      }
    }

    @include media("<=tablet") {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &:first-child {
        margin-right: 30px;
      }
    }

    @include media("<=phone") {
      &:first-child {
        margin-right: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: $main-bg;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
      opacity: 1;
      z-index: 1;
      border-radius: 50%;
    }

    @media (any-pointer: fine) {
      &:hover {
        &::after {
          opacity: 0;
        }

        .c-category__bubble__title {
          // color: $white;
          opacity: 0;
        }
      }
    }
  }
}
