@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../fonts/Satoshi-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/Satoshi-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/Satoshi-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi Var";
  src: url("../fonts/Satoshi-Variable.woff2") format("woff2"),
    url("../fonts/Satoshi-Variable.woff") format("woff");
  font-display: swap;
  font-style: normal;
}
