.brain {
  &__cover {
    @extend %cover;
    position: fixed;
    pointer-events: none;
    z-index: z("brain");
    display: none;

    @media (any-pointer: fine) {
      display: block;
    }

    @include media("<=tablet") {
      display: none;
    }
  }
  &__wrapper {
    position: absolute;
    right: 40px;
    bottom: 40px;
    animation: float 6s ease-in-out infinite;
  }

  &--footer {
    &__wrapper {
      animation: float 6s ease-in-out infinite;
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
